@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    font-family: "Rubik", system-ui, sans-serif;
  }

  html {
    @apply text-[18px] bg-neutral-150 scrollbar-preset;
  }

  html.dark {
    @apply !bg-neutral-800;
  }

  body {
    padding-right: 0px !important;
  }

  #root {
    @apply min-h-screen flex flex-col;
  }

  input[type="date"] {
    @apply dark:[color-scheme:dark];
  }
}

@layer utilities {
  .scrollbar-preset {
    @apply scrollbar scrollbar-w-2 scrollbar-h-2 scrollbar-thumb-neutral-400 scrollbar-thumb-rounded-full scrollbar-cursor-pointer hover:scrollbar-thumb-neutral-450 hover:dark:scrollbar-thumb-neutral-350;
  }

  .scrollbar-preset-thin {
    @apply scrollbar-preset !scrollbar-w-1.5 !scrollbar-h-1.5;
  }

  .message-arrow::after {
    @apply content-[''] absolute bottom-0 w-4 h-4;
  }

  .message-arrow-right::after {
    clip-path: polygon(0 0, 0 100%, 100% 100%);

    @apply bg-blue-500 dark:bg-blue-900 right-0 translate-x-3/4;
  }

  .message-arrow-left::after {
    clip-path: polygon(0 100%, 100% 100%, 100% 0);

    @apply bg-neutral-250 dark:bg-neutral-950 left-0 -translate-x-3/4;
  }

  .language-selector-arrow::after {
    @apply content-[''] absolute bg-neutral-300 dark:bg-neutral-700 top-0 left-1/2 -translate-x-1/2 -translate-y-1/3 w-4 h-4 rotate-45 z-10;
  }

  .company-icon-mask {
    mask-image: radial-gradient(circle at 75% 75%, #0000 32%, #000 33%);
  }

  .status-icon-mask {
    mask-image: radial-gradient(circle at 85% 85%, #0000 22%, #000 23%);
  }

  .status-mask-away {
    mask-image: radial-gradient(circle at 30% 30%, #0000 33%, #000 35%);
  }

  .status-mask-dnd {
    mask-image: url(#dnd-mask);
    mask-size: 20px;
  }

  .status-mask-offline {
    mask-image: radial-gradient(circle at 50% 50%, #0000 34%, #000 35%);
  }

  .checkbox-mask {
    mask-image: linear-gradient(black, black), url("./images/mask/check_icon.svg");
    mask-position: center;
    mask-size: cover;
    mask-composite: subtract;
  }

  .radio-mask {
    mask-image: linear-gradient(black, black), url("./images/mask/radio_icon.svg");
    mask-position: center;
    mask-size: cover;
    mask-composite: subtract;
  }

  .top-blur-mask {
    mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0.5) 97.5%, rgba(0, 0, 0, 0) 100%);
  }

  .bottom-blur-mask {
    mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 2.5%, rgba(0, 0, 0, 1) 10%);
  }

  .top-bottom-blur-mask {
    mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 2.5%, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0.5) 97.5%, rgba(0, 0, 0, 0) 100%);
  }

  .ribbon-mask {
    mask-image: linear-gradient(black, black), url("./images/mask/ribbon_end.svg");
    mask-size: cover;
    mask-composite: intersect;
  }

  .no-spinners {
    appearance: textfield;
    -moz-appearance: textfield;
  }

  .no-spinners::-webkit-outer-spin-button,
  .no-spinners::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 1s ease-out forwards;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10px); /* Moins de mouvement pour un effet subtil */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInDown {
  animation: fadeInDown 1.5s ease-out forwards; /* Durée augmentée pour plus de fluidité */
}

.blink-cursor {
  animation: blink 0.7s step-end infinite;
}

/* :root {
  --background-white: rgb(245, 245, 245);
  --background-white-darker: rgb(200, 200, 200);

  --low-opacity-black: rgb(0, 0, 0, 0.5);
  --very-low-opacity-black: rgb(0, 0, 0, 0.25);
  --low-opacity-white: rgb(255, 255, 255, 0.5);
  --very-low-opacity-white: rgb(255, 255, 255, 0.25);

  --text-white: rgb(250, 250, 250);
  --text-grey: rgb(117, 117, 117);
  --text-black: rgb(20, 20, 20);

  --theme-red: rgb(230, 23, 29);
  --theme-red-light: rgb(233, 39, 45);
  --theme-red-lighter: rgb(236, 61, 66);
  --theme-red-dark: rgb(221, 19, 26);
  --theme-red-darker: rgb(201, 15, 21);
}

body,
input,
select,
button {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
